/* eslint-disable @typescript-eslint/no-unused-vars */
import { createPropParser } from '@/api/parsing/properties.zod'
import { z } from 'zod'

const stringToJSONSchema = z.string().transform((str, ctx) => {
  try {
    return str !== '' ? JSON.parse(str) : str
  } catch (e) {
    ctx.addIssue({ code: 'custom', message: 'Invalid JSON' })
    return z.NEVER
  }
})

const knownProps = z.union([
  z.literal('actualWhen'),
  z.literal('adhocDesc'),
  z.literal('ServiceVisitValidator.AllowedTimeDeviation'),
  z.literal('cancelledState'),
  z.literal('cancellationId'),
  z.literal('children'),
  z.literal('color'),
  z.literal('customerType'),
  z.literal('distance'),
  z.literal('familyMember'),
  z.literal('inactivePeriods'),
  z.literal('lockedToCalendar'),
  z.literal('name'),
  z.literal('originRoute'),
  z.literal('rgroup'),
  z.literal('routeWorktime'),
  z.literal('state'),
  z.literal('transportInSeconds'),
  z.literal('transportMode'),
  z.literal('type'),
  z.literal('firstdate'),
  z.literal('profiles'),
  z.literal('qualifications'),
  z.literal('scope'),
  z.literal('roles'),
  z.literal('visitcount'),
  z.literal('visitduration'),
  z.literal('visitState'),
  z.literal('writtenAddress'),
  z.literal('replacement'),
  z.literal('customerPaid'),
  z.literal('editor'),
  z.literal('timestamp_m'),
  z.literal('id'),
])

export type KnownProps = z.infer<typeof knownProps>

const inactivePeriodSchema = z.object({
  reason: z.string(),
  from: z.string(),
  to: z.string(),
  fromTimeOfDay: z.number().optional(),
  toTimeOfDay: z.number().optional(),
})

const inactivePeriods = createPropParser(
  'inactivePeriods',
  inactivePeriodSchema.array()
)

const visitStateSchema = z.union([
  z.literal('Unknown'),
  z.literal('New'),
  z.literal('Accepted'),
  z.literal('Rejected'),
  z.literal('Completed'),
  z.literal('Cancelled'),
  z.literal('Inactive'),
])

const familyMemberSchema = z.union([z.literal('Parent'), z.literal('Child')])
const customerTypeSchema = z.union([
  z.literal('Internal'),
  z.literal('External'),
])
const customerType = createPropParser('type', customerTypeSchema)

const familyMember = createPropParser('familyMember', familyMemberSchema)
const children = createPropParser('children', z.record(z.unknown()))
const visitState = createPropParser('state', visitStateSchema)
const writtenAddress = createPropParser('writtenAddress', z.string())
const actualWhen = createPropParser(
  'actualWhen',
  z.string().regex(/\d+:\d+/, { message: 'valid format is from:duration' })
)
const cancelledState = createPropParser(
  'cancelledState',
  z.union([z.literal('Suspended'), z.literal('Unknown')])
)

const cancellationId = createPropParser('cancellationId', z.string().optional())

const adhocDesc = createPropParser('adhocDesc', z.string())

const allowedTimeDeviation = createPropParser(
  'ServiceVisitValidator.AllowedTimeDeviation',
  z.coerce.number()
)

const anchorDate = createPropParser('firstdate', z.string())

const originRoute = createPropParser('originRoute', z.string())
const name = createPropParser('name', z.string())

const transportInSeconds = createPropParser(
  'transportInSeconds',
  z.coerce.number()
)
const distance = createPropParser('distance', z.coerce.number())
const visitduration = createPropParser('visitduration', z.coerce.number())
const visitcount = createPropParser('visitcount', z.coerce.number())
const routeWorktime = createPropParser(
  'routeWorktime',
  z.string().regex(/\d+:\d+/, { message: 'valid format is from:to' })
)

const roles = createPropParser('roles', z.string())

// PropertySet roles
const qualifications = createPropParser('qualifications', z.unknown()) //TODO: Add when needed
const profiles = createPropParser('profiles', z.string())
const resourceGroups = createPropParser('rgroup', z.string())
const scope = createPropParser('scope', z.string())

const propertySetRoleWithoutPropsSchema = z.object({
  id: z.string(),
  versionId: z.number(),
  name: z.string(),
  properties: z.string(),
})
const propertySetRoleWithPropsSchema = propertySetRoleWithoutPropsSchema
  .omit({ properties: true })
  .merge(
    z.object({
      properties: z.object({
        qualifications,
        rgroup: resourceGroups,
        scope,
        profiles,
      }),
    })
  )
export const propertySetRolesSchema = stringToJSONSchema.pipe(
  z.record(
    z.string(),
    stringToJSONSchema.pipe(propertySetRoleWithoutPropsSchema)
  )
)
export type Role = z.infer<typeof propertySetRoleWithPropsSchema>

// PropertySet cancellations
const replacement = createPropParser('replacement', z.string().optional())
const customerPaid = createPropParser('customerPaid', z.string().optional())

const propertySetCancellationWithoutPropsSchema = z.object({
  id: z.string(),
  name: z.string(),
  properties: z.string(),
})
const propertySetCancellationWithPropsSchema =
  propertySetCancellationWithoutPropsSchema.omit({ properties: true }).merge(
    z.object({
      properties: z.object({
        replacement,
        customerPaid,
      }),
    })
  )
export const propertySetCancellationsSchema = stringToJSONSchema.pipe(
  z.record(
    z.string(),
    stringToJSONSchema.pipe(
      propertySetCancellationWithoutPropsSchema.or(z.string())
    )
  )
)

export type Cancellation = z.infer<
  typeof propertySetCancellationWithPropsSchema
>

const activityLogProperties = {
  distance,
  routeWorktime,
  transportInSeconds,
  visitduration,
  visitcount,
} as const

const resourceGroupProperties = {
  name,
} as const

const employeeProperties = {
  name,
  resourceGroups,
  roles,
} as const

const customerProperties = {
  type: customerType,
  writtenAddress,
} as const

const serviceVisitProperties = {
  adhocDesc,
  actualWhen,
  cancelledState,
  cancellationId,
  children,
  originRoute,
  familyMember,
  inactivePeriods,
  visitState,
  anchorDate,
}

const serviceAgreementProperties = {
  adhocDesc,
  actualWhen,
  children,
  cancelledState,
  customerType,
  inactivePeriods,
}

const geoLocationProperties = {
  writtenAddress,
} as const

// Route properties
const color = createPropParser('color', z.coerce.number())
const lockedToCalendar = createPropParser('lockedToCalendar', z.boolean())
const transportMode = createPropParser(
  'transportMode',
  z.union([
    z.literal('Car'),
    z.literal('Bike'),
    z.literal('Walking'),
    z.literal('None'),
    z.literal('Stationary'),
  ])
)

const propertySetRoleProperties = {
  qualifications,
  resourceGroups,
  scope,
  profiles,
} as const

const propertySetCancellationProperties = {
  replacement,
  customerPaid,
} as const

const routeProperties = {
  name,
  color,
  lockedToCalendar,
  transportMode,
} as const

const propertySetServerConfigurationProperties = {
  allowedTimeDeviation,
} as const

const allPropertySchemas = {
  ...routeProperties,
  ...activityLogProperties,
  ...customerProperties,
  ...serviceVisitProperties,
  ...serviceAgreementProperties,
  ...geoLocationProperties,
  ...resourceGroupProperties,
  ...employeeProperties,
  ...propertySetRoleProperties,
  ...propertySetServerConfigurationProperties,
  ...propertySetCancellationProperties,
} as const

export const PropertySchemas = {
  customer: customerProperties,
  route: routeProperties,
  activityLog: activityLogProperties,
  serviceVisit: serviceVisitProperties,
  serviceAgreement: serviceAgreementProperties,
  geoLocation: geoLocationProperties,
  employee: employeeProperties,
  resourceGroup: resourceGroupProperties,
  propertySetRole: propertySetRoleProperties,
  propertySetServerConfiguration: propertySetServerConfigurationProperties,
  propertySetCancellation: propertySetCancellationProperties,
} as const

export type PropertySchema =
  (typeof allPropertySchemas)[keyof typeof allPropertySchemas]
