import { useEffect, useState } from 'react'
import { create, get } from '@alexandrainst/webauthn-pin-storage'

const storeSecret = async (secret: string) => {
  return await create(secret, 'PlanA', 'Login')
}

const getSecret = async (id: string) => {
  return await get(id)
}

export const useBiometricPasswordStorage = () => {
  const [hasBiometricFeature, setHasBiometricFeature] = useState(false)

  useEffect(() => {
    const checkForFeature = async () => {
      try {
        setHasBiometricFeature(
          await PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable()
        )
      } catch {
        setHasBiometricFeature(false)
      }
    }
    checkForFeature()
  }, [])

  return {
    hasBiometricFeature,
    storeSecret,
    getSecret,
  }
}
