import React, { Suspense } from 'react'
import { i18nInit } from '@/features/i18n'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import { store } from '@/app'
import { createRoot } from 'react-dom/client'
import './index.css'
import { OpenFeatureClientProvider } from './incubator/featureFlags/FeatureFlagProvider'
import { FlagdWebProvider } from '@openfeature/flagd-web-provider'
import { Provider } from 'react-redux'
import { Loading } from './features/loading'
import { PlanAMobileApp } from '@/shell/mobile'
import { PlanADesktopApp } from '@/shell/desktop'
import { isDesktop } from './app/platform'
import { initErrorReporting } from '@/features/error-reporting'

const App = isDesktop() ? PlanADesktopApp : PlanAMobileApp

i18nInit()
initErrorReporting()

const flagProvider = new FlagdWebProvider({
  host: import.meta.env.VITE_REACT_APP_FLAG_SERVER,
  port: 443,
  tls: true,
  maxRetries: 10,
  maxDelay: 30000,
})

const persistor = persistStore(store)

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <OpenFeatureClientProvider provider={flagProvider}>
          <Suspense fallback={<Loading />}>
            <App />
          </Suspense>
        </OpenFeatureClientProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
)
