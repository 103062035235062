import * as Sentry from '@sentry/react'
import { replaySessionSampleRate } from '@/app/configuration'

function shouldIgnoreError(error: Sentry.ErrorEvent) {
  const now = Date.now()
  if (!error.breadcrumbs) {
    return false
  }

  // We go from the back since the last breadcrumb is most likely the erroneous one
  for (let i = error.breadcrumbs.length - 1; i >= 0; i--) {
    const breadcrumb = error.breadcrumbs[i]
    if (!breadcrumb) continue

    // We only need to check the last 5s of breadcrumbs as any earlier breadcrumbs are definitely unrelated
    if (breadcrumb.timestamp && now - breadcrumb.timestamp * 1000 > 5000) {
      break
    }

    if (isThirdPartyBreadcrumb(breadcrumb)) {
      return true
    }
  }

  return false
}

function isThirdPartyBreadcrumb(breadcrumb: Sentry.Breadcrumb): boolean {
  if (
    breadcrumb.level !== 'error' ||
    (breadcrumb.category !== 'xhr' && breadcrumb.category !== 'fetch')
  ) {
    return false
  }

  const url = breadcrumb.data?.url as string | undefined
  if (!url) {
    return false
  }

  return isThirdPartyUrl(url)
}

function isThirdPartyUrl(url: string): boolean {
  if (url.includes('alexandra.dk')) {
    return false
  }
  return true
}

export const initErrorReporting = () => {
  Sentry.init({
    dsn: import.meta.env.VITE_REACT_APP_SENTRY_DSN,
    environment: import.meta.env.MODE + '-' + import.meta.env.VITE_PLATFORM,
    release: (process.env.gitVersion || '') + (process.env.gitCommitHash || ''),
    autoSessionTracking: true,
    enableTracing: true,
    integrations: [
      Sentry.captureConsoleIntegration({
        levels: ['error', 'warn'],
      }),
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    replaysOnErrorSampleRate: 1.0,
    beforeSend: event => (shouldIgnoreError(event) ? null : event),
    replaysSessionSampleRate: replaySessionSampleRate,
    beforeBreadcrumb: breadcrumb => {
      if (breadcrumb && breadcrumb.category === 'fetch') {
        const method = breadcrumb.data?.method
        const url = breadcrumb.data?.url
        const status = breadcrumb.data?.status_code
        if (method === 'GET' && url?.includes('since=') && status === 200) {
          return null
        }
      }
      return breadcrumb
    },
  })
}
