import * as Sentry from '@sentry/react'
import { ApiClient } from '@alexandrainst/plana-react-api'
import {
  Client,
  EvaluationContext,
  JsonValue,
  OpenFeatureEventEmitter,
  Provider,
  ProviderStatus,
  ResolutionDetails,
} from '@openfeature/web-sdk'
import { z } from 'zod'

const booleanParamSchema = z
  .enum(['true', 'false'])
  .transform(value => value === 'true')

const featuresSchema = z.object({
  allowDebug: booleanParamSchema.optional(),
  useActualTime: booleanParamSchema.optional(),
})

type UserControlledFeatureNames = keyof z.infer<typeof featuresSchema>

// implement the provider interface
export class PropertiesProvider implements Provider {
  // Adds runtime validation that the provider is used with the expected SDK
  public readonly runsOn = 'client'

  readonly metadata = {
    name: 'PlanA Properties Provider',
  } as const

  private client: ApiClient
  private parentFeatureClient: Client
  private appFeatures: z.infer<typeof featuresSchema>
  private context: EvaluationContext | undefined
  private timer: NodeJS.Timeout | string | number | undefined

  constructor(client: ApiClient, parentFeatureClient: Client) {
    this.client = client
    this.parentFeatureClient = parentFeatureClient
    this.appFeatures = {}
  }

  resolveBooleanEvaluation(
    flagKey: string,
    defaultValue: boolean
  ): ResolutionDetails<boolean> {
    if (flagKey in this.appFeatures) {
      return {
        value:
          this.appFeatures[flagKey as UserControlledFeatureNames] ??
          defaultValue,
      }
    }
    const value = this.parentFeatureClient.getBooleanValue(
      flagKey,
      defaultValue
    )
    return {
      value,
    }
  }

  resolveStringEvaluation(
    flagKey: string,
    defaultValue: string
  ): ResolutionDetails<string> {
    if (flagKey in this.appFeatures) {
      const value = this.appFeatures[flagKey as UserControlledFeatureNames]
      return {
        value: typeof value === 'string' ? value : defaultValue,
      }
    }

    const value = this.parentFeatureClient.getStringValue(flagKey, defaultValue)
    return {
      value,
    }
  }

  resolveNumberEvaluation(
    flagKey: string,
    defaultValue: number
  ): ResolutionDetails<number> {
    if (flagKey in this.appFeatures) {
      const value = this.appFeatures[flagKey as UserControlledFeatureNames]
      return {
        value: typeof value === 'number' ? value : defaultValue,
      }
    }

    const value = this.parentFeatureClient.getNumberValue(flagKey, defaultValue)
    return {
      value,
    }
  }

  resolveObjectEvaluation<T extends JsonValue>(
    flagKey: string,
    defaultValue: T
  ): ResolutionDetails<T> {
    if (flagKey in this.appFeatures) {
      const value = this.appFeatures[flagKey as UserControlledFeatureNames]
      return {
        value: typeof value === 'object' ? value : defaultValue,
      }
    }

    const value = this.parentFeatureClient.getObjectValue(
      flagKey,
      defaultValue
    ) as T
    return {
      value,
    }
  }

  // status?: ProviderStatus | undefined
  events?: OpenFeatureEventEmitter | undefined

  status?: ProviderStatus = ProviderStatus.NOT_READY

  async initialize?(context?: EvaluationContext | undefined): Promise<void> {
    this.context = context
    await this.startFetching()

    return Promise.resolve()
  }

  private async startFetching() {
    if (this.timer !== undefined) {
      clearTimeout(this.timer)
    }
    await this.fetchProperties()
    this.timer = setTimeout(async () => this.startFetching(), 60 * 1000)
  }

  private async fetchProperties() {
    try {
      const app_features = await this.client.fetchResource(
        'PropertySet',
        'app_features'
      )
      const appFeatures = featuresSchema.safeParse(
        JSON.parse(app_features?.data.properties ?? '{}')
      )
      if (appFeatures.success) {
        this.status = ProviderStatus.READY
        this.appFeatures = appFeatures.data
      } else {
        this.status = ProviderStatus.ERROR
        console.warn(
          'Error parsing feature configuration',
          appFeatures.error,
          app_features
        )
        Sentry.captureMessage(
          `Feature flag parse error - ${JSON.stringify(this.context)}\n ${
            appFeatures.error.message
          }`,
          'error'
        )
      }
    } catch {
      if (this.status !== ProviderStatus.READY) {
        this.status = ProviderStatus.ERROR
      }
    }
  }

  onClose?(): Promise<void> {
    if (this.timer !== undefined) {
      clearTimeout(this.timer)
    }
    return Promise.resolve()
  }
}
